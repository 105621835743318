<template>
  <eva-page class="eva-login-page">
    <eva-layout row fill class="eva-background-2" style="position: relative">
      <eva-image cover src="/api/v1/assets/login_background" transparent/>
    </eva-layout>
    <eva-layout row width="600px" class="eva-background-5">
      <eva-layout row class="eva-login-page__header">
        <eva-image src="/api/v1/assets/login_logo" class="eva-login-page__logo" transparent/>
        <eva-text header :text="platformName"/>
      </eva-layout>
      <eva-form
        :settings="settings"
        :model="model"
        ref="form"
      >
        <template v-slot:footer>
          <eva-btn
            label="Войти в систему"
            type="secondary"
            class="eva-login-page__login"
            @click="login"
          />
        </template>
      </eva-form>
    </eva-layout>
  </eva-page>
</template>

<script>
export default {
  name: 'eva-login-page',

  data() {
    return {
      model: {
        login: '',
        password: ''
      },
      settings: {
        prefix: this.$options.name,
        columns: {
          login: {
            type: 'text',
            validators: {
              require: {}
            }
          },
          password: {
            password: true,
            type: 'text',
            validators: {
              require: {}
            }
          }
        }
      }
    }
  },

  computed: {
    platformName() {
      return (this.$context.platform && this.$context.platform.platformName)
        ? this.$context.platform.platformName[this.$locale.current]
        : '';
    }
  },

  mounted() {
    document.addEventListener('keypress', event => {
      if (event.key === 'Enter') this.login();
    });
  },

  methods: {
    async login() {
      if (await this.$refs.form.validate()) {
        return;
      }
      try {
        await this.$eva.$http.post('/api/v1/users/login', this.model);
        window.location.href = "/#/";
      } catch (error) {
        let message = error.response && error.response.data;
        this.$eva.$logs.error(this.$options.name, `Во время входа в систему произошла ошибка.`, error);
        this.$eva.$boxes.notifyError(message || `Во время входа в систему произошла ошибка.`);
      }
    }
  }
}
</script>

<style lang="less">
.eva-login-page {
  .eva-page {
    display: flex;
    flex-direction: row;
  }
  .eva-login-page__header {
    position: absolute;
    top: (3 * @eva-padding);
    right: (3 * @eva-padding);
    width: auto;
    background-color: transparent;
  }
  .eva-login-page__logo {
    width: auto;
    height: auto;
  }
  .eva-login-page__login {
    width: 180px;
    margin-top: 8px;
  }
  .eva-form {
    width: 350px;
    margin: auto;
    min-height: auto;
  }
}
</style>

<locale lang="ru">
{
  columns: {
    login: {
      header: 'Логин'
    },
    password: {
      header: 'Пароль'
    }
  }
}
</locale>

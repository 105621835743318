import app from '../../common/app';
import evaApp from '@eva/client/app';
import LoginPage from "./LoginPage";

(async function bootstrap() {
  await evaApp.init();
  await app.init();



  evaApp.bootstrap(LoginPage);
})();
